
.container-form-preview{
  display: flex;
  .preview-mail{
    min-width: 300px;
    width:300px;
    padding-left: 30px;
  }
}
.header-image{
    height: 159px!important;
  }
  .block-image{
    height: 91px!important;
  }
:deep().el-upload {
  width: 100%;
  height: 100%!important;
  .el-upload-list__item-actions{
    background-color: rgba(0,0,0, 0.5);
    opacity: 0;
    position:absolute;
    width: 100%;
    height: 100%;
    &:hover{
      opacity: 1;
      display: block;
    }
    .el-upload-list__item-delete{
      display: block;
      padding-top: 10px;
      color: #ffffff;
    }
  }
}
:deep().el-upload-dragger {
  width: 100%;
  margin: 0;
  border-radius: 5px;
  border: 2px dashed #86d3f7;
  background: transparent;
  height: 100%;

  .el-upload__text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #012454;
    font-weight: 600;

  }
  .avatar,
  .preview {
    height: 180px;
    width: 100%;
    object-fit: cover;
    &:hover{
      opacity:1
    }
  }
}
.add-icon-size {
  height: 20px;
}
.button-icon {
  background-color: none !important;
  border: 1px solid #aab1be;
  &:hover {
    background-color: transparent;
  }
}
:deep().min-height-textarea{
  height: 91px;
}
:deep().ql-toolbar{
  border: 1px solid #c6dcf5;
  border-radius: 5px !important;
  border-bottom-color: #ffffff ;
}
:deep().ql-container{
    box-shadow: inset 0 1px 3px rgba(1, 36, 84, 0.3) !important;
    border: 1px solid #c6dcf5 !important;
    border-top-color: transparent;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    .ql-editor{

      min-height: 117px;
      max-height: 117px;
      box-shadow: inset 0 1px 3px rgba(1, 36, 84, 0.3) !important;
      width: 100%;
      overflow-y: auto;
      background-color: #ffffff !important;
      font-size: 13px !important;
      color: #2b2b2d !important;
      font-family: Montserrat, sans-serif;
      border: none;
      strong{
        font-weight: 600;
      }
    }
    &.ql-disabled .ql-editor{
      background-color: #E8EEF2!important;
    }
}
.h-fit{
  height: fit-content;
}
.back-button{
  width: fit-content;
}
.info-url{
  font-size: 11px;
  font-weight: 600;
}

.mail-form{
  :deep() .textarea-header{
    height: 77px!important;
    width: 100%;
  }
  :deep() .textarea-block{
    height: 91px!important;
  }
  :deep() .textarea-footer{
    height: 117px!important;
  }
  .btn-light-blue{
    border-color: #0373a8;
    color: #0373a8;
    font-size: 14px;
    font-weight: 500;
    min-width: 166px;
    height: 33px;
    margin-top: 8px;
    margin-bottom: 26px;
    &:hover{
      color: white;
      background: #0373a8;
    }
  }
  .icon-card{
    cursor: pointer;
    width: auto;
  }

  .option-text{
    .max-input{
      width: 109px;
    }
    :deep().form-group{
      margin-bottom: 0px;
    }
  }
}
.custom-picker{
  display: block;
  position: absolute;

}
:deep().input-stevie.input-link{
  padding-left: 33px!important;
  background: url('/img/icons/stevie/link-horizontal.svg') no-repeat left;
  background-size: 15px;
  background-position: 8px 11px;
}
.custom-card{
  background-color: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 20px 21px;
  border-radius: 20px;
}

.segment{
  margin: 0 -21px;
  padding: 22px 31px 21px;
  &.footer{
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.highlight-segment{
    background-color: #E7F6FD;
  }

}

.error-image-top {
  top: 12rem;
}

@media(max-width: 1350px) {
  .container-form-preview{
    flex-direction: column;
    .preview-mail{
      width: 100%;
      padding-left: 0;
      padding-top: 30px;
    }
  }
}

